<template>
  <b-overlay
    :show="!cargando"
    spinner-variant="primary"
  >
    <b-card>
      <b-form @submit.prevent="$emit('processSalida', salida)">

        <!-- Salida Info: Input Fields -->
        <b-form>
          <b-card-title>Información</b-card-title>
          <b-row>

            <!-- Field: Referencia -->
            <b-col
              cols="12"
              md="4"
            >
              <b-form-group
                label="Referencia"
                label-for="referencia"
              >
                <b-form-input
                  id="referencia"
                  v-model="salida.referencia"
                  :state="$v.salida.referencia.$invalid == true ? false : null"
                  placeholder="Introduce la referencia"
                  @input="$v.salida.$touch"
                />
                <b-form-invalid-feedback
                  v-if="$v.salida.$dirty"
                  id="referenciaInfo"
                >
                  El campo referencia
                  <span v-if="!$v.salida.referencia.maxLength"> debe tener max 200 caracteres.</span>
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>

            <!-- Field: idCliente -->
            <b-col
              cols="12"
              md="4"
            >
              <b-form-group
                label="Cliente"
                label-for="cliente"
              >
                <v-select
                  v-model="salida.idCliente"
                  placeholder="Selecciona un cliente"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="text"
                  :options="clientesOption"
                  :reduce="option => option.value"
                  input-id="idCliente"
                />
              </b-form-group>
            </b-col>

            <!-- Field: Fecha -->
            <b-col
              cols="12"
              md="4"
            >
              <b-form-group
                label="Fecha"
                label-for="fecha"
              >
                <b-form-datepicker
                  id="fecha"
                  local="es"
                  v-model="salida.fecha"
                  :state="$v.salida.fecha.$invalid == true ? false : null"
                  placeholder="Introduce la fecha"
                  @input="$v.salida.$touch"
                  start-weekday="1"
                  :hide-header="true"
                  :show-decade-nav="true"
                />
                <b-form-invalid-feedback
                  v-if="$v.salida.$dirty"
                  id="fechaInfo"
                >
                  El campo fecha
                  <span v-if="!$v.salida.fecha.required"> es requerido.</span>
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>

          </b-row>

          <colLinea />

          <b-card-title class="mt-2">Productos</b-card-title>
          <b-row>

            <!-- Field: PRODUCTOS -->
            <b-col
              cols="12"
              md="12"
            >
              <salidasProductos
                :update="update"
                :salidasProductos.sync="salida.salidasProductos"
                :valorTotal.sync="valorTotal"
              />
            </b-col>

          </b-row>

          <colLinea />

        </b-form>

        <!-- Action Buttons -->
        <btnSubmit
          v-if="cargando"
          :btnDisabled="!Number(valorTotal) > 0"
          :btnText="salidaSubmit"
        />

      </b-form>
    </b-card>
  </b-overlay>
</template>

<script>
import {
  BRow, BCol, BFormGroup, BFormInput, BForm, BFormInvalidFeedback,
  BCardTitle, BFormDatepicker, BOverlay, BCard,
} from 'bootstrap-vue'
import { validationMixin } from 'vuelidate'
import {
  required, maxLength,
} from 'vuelidate/lib/validators'
import { soloNumerosTramo } from '@/mixins/forms'
import { mapActions, mapGetters } from 'vuex'
import { avatarText } from '@core/utils/filter'
import { ref } from '@vue/composition-api'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import vSelect from 'vue-select'

const btnSubmit = () => import('@/layouts/components/Recycled/Form/btnSubmit.vue')
const salidasProductos = () => import('@/layouts/components/Salidas/SalidasFormProductos.vue')
const colLinea = () => import('@/layouts/components/Recycled/Form/colLinea.vue')

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BFormInvalidFeedback,
    BCardTitle,
    BFormDatepicker,
    vSelect,
    BOverlay,
    BCard,

    btnSubmit,
    salidasProductos,
    colLinea,
  },
  mixins: [validationMixin, soloNumerosTramo],
  props: {
    salida: {
      type: Object,
      required: true,
    },
    salidaSubmit: {
      type: String, // Texto del boton
      default: 'Guardar Salida',
    },
    update: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      clientesOption: [],
      cargando: false,
      valorTotal: 0,
    }
  },
  computed: {
    ...mapGetters({
      getClientesActivos: 'clientes/getClientesActivos',
      getClientes: 'clientes/getClientes',
      getProductos: 'productos/getProductos',
      getUserAuth: 'auth/authenticated',
    }),
  },
  watch: {
    valorTotal() {
      this.salida.valorTotal = this.valorTotal
    },
    getClientesActivos() {
      this.getClientesActivos.forEach(cliente => {
        const rutNombre = `${cliente.rut} - ${cliente.nombre}`
        this.clientesOption.push({
          value: `${cliente.id}`,
          text: `${rutNombre}`,
        })
      })
      this.cargando = true
    },
  },
  validations() {
    return {
      salida: {
        fecha: {
          required,
        },
        referencia: {
          maxLength: maxLength(200),
        },
      },
    }
  },
  mounted() {
    this.fetchClientesActivos()
    this.fetchProductos()
    this.salida.idUsuario = this.getUserAuth.id
    if (this.update) {
      this.salida.fecha = this.formatoFecha(this.salida.fecha)
    } else {
      const dia = this.salida.fecha.getDate()
      const mes = (this.salida.fecha.getMonth() + 1)
      const year = this.salida.fecha.getFullYear()
      this.salida.fecha = `${year}-${mes}-${dia}`
    }
  },
  methods: {
    ...mapActions({
      fetchClientesActivos: 'clientes/fetchClientesActivos',
      fetchProductos: 'productos/fetchProductos',
    }),
    formatoFecha(fecha) {
      return fecha.replace(/^(\d{2})-(\d{2})-(\d{4})$/g, '$3-$2-$1')
    },
    soloNumeros() {
      const expRegNumero = /[^\d]/g
      if (this.salida.valorTotal != null) {
        const validation = expRegNumero.test(this.salida.valorTotal)
        if (validation) {
          this.salida.valorTotal = this.salida.valorTotal.replace(expRegNumero, '')
        }
      }
      if (this.salida.valorTotal.length === 0) {
        this.salida.valorTotal = '0'
      }
    },
    dateDisabled(ymd, date) {
      // Disable weekends (Sunday = `0`, Saturday = `6`) and
      // disable days that fall on the 13th of the month
      const weekday = date.getDay()
      const day = date.getDate()
      // Return `true` if the date should be disabled
      return weekday === 0 || weekday === 6 || day === 13
    },
  },
  setup() {
    const refInputEl = ref(null)
    const previewEl = ref(null)

    const { inputImageRenderer } = useInputImageRenderer(refInputEl, base64 => {
      // eslint-disable-next-line no-param-reassign
      this.salida.imagen = base64
    })

    return {
      previewEl,
      avatarText,
      inputImageRenderer,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
